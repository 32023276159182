define('m20/controllers/contact', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    debug: true,

    message: "",
    sname: "",
    email: "",
    testemail: "",

    sendingMail: false,

    nachrichtVerfassen: true,

    actions: {

      sendMessage: function sendMessage() {

        var self = this;

        if (self.get('testemail') === "") {

          console.log('sendMessage()');

          self.set('sendingMail', true);

          var messageToSend = self.get('message');
          var nameOfSender = self.get('sname');
          var email = self.get('email');

          var application = self.controllerFor('application');

          var applicationdata = application.get('model').application.objectAt(0);
          var applicationdataJSON = JSON.stringify(applicationdata);
          //
          // var projektdatendata = application.get('model').projektdaten.objectAt(0);
          // var projektdatendataJSON = JSON.stringify(projektdatendata);
          //
          // var bauteiledata = application.get('model').bauteile.objectAt(0);
          // var bauteiledataJSON = JSON.stringify(bauteiledata);
          //
          // var anschlussgeometriedata = application.get('model').anschlussgeometrie.objectAt(0);
          // var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);
          //
          // var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
          // var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);
          //
          // if (self.debug) {
          //   console.log("projektdatendata: " + projektdatendataJSON);
          //   console.log("applicationdataJSON: " + applicationdataJSON);
          //   console.log("bauteiledataJSON: " + bauteiledataJSON);
          //   console.log("anschlussgeometriedataJSON: " + anschlussgeometriedataJSON);
          //   console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
          // }
          //
          // var projektdatendataJSONcut = projektdatendataJSON.substring(1, projektdatendataJSON.length - 1);
          // var bauteiledataJSONcut = bauteiledataJSON.substring(1, bauteiledataJSON.length - 1);
          // var anschlussgeometriedataJSONcut = anschlussgeometriedataJSON.substring(1, anschlussgeometriedataJSON.length - 1);
          // var lasteinwirkungdataJSONcut = lasteinwirkungdataJSON.substring(1, lasteinwirkungdataJSON.length - 1);
          //
          // var separators = [':', ','];
          // if (self.debug) {
          //   console.log(separators.join('|'));
          // }
          // var bauteildataarray = bauteiledataJSONcut.split(new RegExp(separators.join('|'), 'g'));
          // console.log(bauteildataarray);
          //
          //
          // //var timberelementstringht = "\"TimberElementHT\" : { \"Querschnittswerte\" : { \"b\" : " + bauteildataarray[5] + ",  \"h\": " + bauteildataarray[7] + " } },";
          // var timberelementstringht = "\"MatKeyTimberElementHT\" :" + bauteildataarray[1] + ", \"FKLKeyTimberElementHT\" :" + bauteildataarray[3] + ", \"TimberElementHT\" : { \"Querschnittswerte\" : { \"b\" : " + bauteildataarray[5] + ",  \"h\": " + bauteildataarray[7] + " } },";
          // var timberelementstringnt = "\"MatKeyTimberElementNT\" :" + bauteildataarray[13] + ", \"FKLKeyTimberElementNT\" :" + bauteildataarray[15] + ", \"TimberElementNT\" : { \"Querschnittswerte\" : { \"b\" : " + bauteildataarray[17] + ",  \"h\": " + bauteildataarray[19] + " } }";
          // //var timberelementstringnt = "\"TimberElementNT\" : { \"Querschnittswerte\" : { \"b\" : " + bauteildataarray[17] + ",  \"h\": " + bauteildataarray[19] + " } }";
          //
          // var bauteileString = "\"Min_sSchrOK\" : " + bauteildataarray[25] + ", \"Min_sSchrUK\" : " + bauteildataarray[27] + ", \"Predrilled\" : " + bauteildataarray[29] + ", \"HtRiegel\" : " + bauteildataarray[11] + ", \"Torsionssteif\" : " + bauteildataarray[23];
          //
          // var JSONdata = "{" + bauteileString + "," + timberelementstringht + timberelementstringnt + "," + anschlussgeometriedataJSONcut + "," + lasteinwirkungdataJSONcut + "}";
          var JSONdata = self.controllerFor('supercontroller').uebergabedatenErzeugen();

          var server = application.get('server');
          var pfad = application.get('pfad');

          var cid = application.get('Calculation_ID');

          if (self.debug) {
            console.log('cid: ' + cid);
            console.log('server: ' + server);
            console.log('pfad: ' + pfad);

            console.log('nameOfSender: ' + nameOfSender);
            console.log('messageToSend: ');
            console.log(messageToSend);
            console.log('kennung: ');
            console.log(applicationdataJSON);
            console.log('paras:');
            console.log(JSONdata);
          }

          $.ajax({
            type: "POST",
            url: server + "sendeEmail/",
            data: {
              kennung: applicationdataJSON,
              paras: JSONdata,
              cid: cid,
              body: messageToSend,
              name: nameOfSender,
              subj: 'M06 HTML CLIENT - Kontaktformular / Anfrage',
              sender: email,
              receiver: "support@swg-engineering.de",
              suffix: "hi06"
            }
          }).done(function (data) {
            self.set('sendingMail', false);
            console.log(data);
            self.set('nachrichtVerfassen', false);

            // datei öffnen, bzw. link anzeigen
          });
        }
      },

      neueNachrichtVerfassen: function neueNachrichtVerfassen() {
        var self = this;
        self.set('message', "");
        this.transitionToRoute('projektdaten');
        self.set('nachrichtVerfassen', true);
      }

    }
  });

});